import CategoryFilter from "./CategoryFilter";
import Testimonials from "./Testimonials";

function ImmediateOpportunities(el) {
  const categoryFilter = CategoryFilter(el.querySelector(".category-filter"));
  const openCampaignsSection = el.querySelector(".immediate-opportunities__open-section");
  const closedCampaignsSection = el.querySelector(".immediate-opportunities__recently-closed-section");
  const openCampaignCount = el.querySelector(".immediate-opportunities__heading__count");
  const openCampaigns = Array.from(openCampaignsSection.querySelectorAll(".campaign"));
  const closedCampaigns = Array.from(closedCampaignsSection.querySelectorAll(".campaign"));
  const noMatchingCampaigns = el.querySelector(".immediate-opportunities__no-campaigns");
  const noMatchingCampaignsClearButton = noMatchingCampaigns.querySelector("button");

  Testimonials(el.querySelector(".testimonials-container"));
  
  categoryFilter.onCategoryChange((categoryId) => {
    if (!document.startViewTransition) {
      updateOpenCampaignsSection(categoryId)
    } else {
      document.startViewTransition(() => {
        updateOpenCampaignsSection(categoryId);
      });
    }
    updateClosedCampaignsSection(categoryId);
  });

  noMatchingCampaignsClearButton.addEventListener("click", () => {
    categoryFilter.clear();
  });

  function updateOpenCampaignsSection(categoryId) {
    const numberOfOpenCampaigns = updateCampaignsVisibility(openCampaigns, categoryId);
    updateNoOpenCampaignsBanner(numberOfOpenCampaigns);
    openCampaignCount.textContent = String(numberOfOpenCampaigns);
  }

  function updateCampaignsVisibility(campaigns, categoryId) {
    let numberVisible = 0;
    campaigns.forEach(campaign => {
      const visible = updateCampaignVisibility(campaign, categoryId);
      if (visible) {
        numberVisible++;
      }
    });
    return numberVisible;
  }

  function updateCampaignVisibility(campaign, categoryId) {
    if (categoryId === "-1" || categoryIds(campaign).includes(categoryId)) {
      campaign.classList.remove("campaign--hidden");
      return true;
    } else {
      campaign.classList.add("campaign--hidden");
      return false
    }
  }

  function categoryIds(campaign) {
    return campaign.getAttribute("data-category-ids").split(",");
  }

  function updateClosedCampaignsSection(categoryId) {
    const numberOfClosedCampaigns = updateCampaignsVisibility(closedCampaigns, categoryId);
    updateVisibilityClass(closedCampaignsSection, numberOfClosedCampaigns, "immediate-opportunities__recently-closed-section--hidden");
  }

  function updateNoOpenCampaignsBanner(visibleCount) {
    updateVisibilityClass(noMatchingCampaigns, visibleCount, "immediate-opportunities__no-campaigns--visible")
  }

  function updateVisibilityClass(element, visibleCount, className) {
    if (visibleCount === 0) {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  }
}

export default ImmediateOpportunities;